/* eslint-disable */
import React from 'react'
import { graphql } from 'gatsby'

import motherDaughter from '../images/heroes/mother-daughter.jpg'
import coupleWalkingBeach from '../images/people/beach-walking.jpg'

import { StaticImage } from 'gatsby-plugin-image'
import CountdownTimer from '../components/Countdown'
import DynamicCTACard from '../components/DynamicCTACard'
import SeoCtaBar from '../components/SeoCtaBar'

import {
  Columns,
  Column,
  Hero,
  LinkButton,
  List,
  ListItem,
  SplitContent,
  Typography,
  VariableContent,
} from '@leshen/gatsby-theme-leshen'
import { Layout } from '@leshen/gatsby-theme-contentful'

import footer from '../components/Footer'
import header from '../components/Header'

const Home = ({ data }) => {
  const brandyList = ['disclaimer-aet-apply']

  const layoutProps = {
    data: {
      contentfulPage: {
        header: header(),
        footer: footer(brandyList, data?.allBrandyDisclaimer?.edges),
        seo: {
          title:
            'Aetna 2023 Medicare Insurance | 855-200-5685 | Plans & Coverage',
          description:
            'Compare Aetna Medicare plans and let us help you enroll in the one that fits your healthcare needs. Call us today at 855-200-569',
          canonical: 'https://www.aetnamedicaredirect.com/',
          robots: 'follow,index',
        },
        path: '/apply',
        promoCode: '148601',
        ringpool: 'MED',
        hideStickyCTA: false,
        spanish: false,
        sections: [
          {
            // Add any relevant section data
          },
        ],
      },
      site: {
        siteMetadata: {
          siteURL: 'https://www.aetnamedicaredirect.com/',
          siteName: 'aetnamedicaredirect',
          alternateName: 'aetnamedicaredirect',
          removeTrailingSlashes: true,
        },
      },
    },
    titleTemplate: 'aetnamedicare',
    defaultRobots: 'follow,index',

    main: (
      <>
        <div className="hero-content">
          <div className="wrapper">
            <Hero
              variant="full"
              className="leshen-hero"
              image={
                <img
                  src={motherDaughter}
                  alt="elderly talks on mobile phone and smiles"
                />
              }
              mainContent={
                <>
                  <Typography variant="h1">
                    Enroll in a Medicare Plan Today!{' '}
                  </Typography>

                  <Typography variant="h4">
                    Our process is quick, easy, and confidential{' '}
                  </Typography>

                  <DynamicCTACard />
                </>
              }
            />
          </div>
        </div>
        <SeoCtaBar />
        <CountdownTimer />
        <VariableContent
          alignMainContent="center"
          mainContent={
            <Typography variant="h2">Medicare Enrollment 101</Typography>
          }
        >
          <Columns className="icon-two-column">
            <Column className="icon-column">
              <StaticImage
                src="../images/icons/purple-people.svg"
                alt="people icon"
                layout="fixed"
                width={100}
                height={100}
                className="column-icon"
              />
            </Column>
            <Column className="content-column">
              <Typography variant="h5" color="primary">
                Who can enroll
              </Typography>
              <Typography variant="body">
                If you are over 65 and a U.S. resident, you qualify for
                Medicare. If you’re under 65, you may still qualify if you have
                Lou Gehrig's Disease (ALS), End Stage Renal Disease, or other
                disabilities.
              </Typography>
            </Column>
          </Columns>
          <Columns className="icon-two-column">
            <Column className="icon-column">
              <StaticImage
                src="../images/icons/purple-network.svg"
                alt="network icon"
                layout="fixed"
                width={100}
                height={100}
                className="column-icon"
              />
            </Column>
            <Column className="content-column">
              <Typography variant="h5" color="primary">
                Why enroll
              </Typography>
              <Typography variant="body">
                Medicare is a federally funded healthcare program that helps
                those over 65 and some under 65 get the healthcare benefits they
                need. It’s important to keep yourself healthy, so when health
                emergencies come your way, Medicare coverage can help you get
                back on your feet.
              </Typography>
            </Column>
          </Columns>
          <Columns className="icon-two-column">
            <Column className="icon-column">
              <StaticImage
                src="../images/icons/purple-cards.svg"
                alt="cards icon"
                layout="fixed"
                width={100}
                height={100}
                className="column-icon"
              />
            </Column>
            <Column className="content-column">
              <Typography variant="h5" color="primary">
                What to know before enrolling
              </Typography>
              <Typography variant="body">
                Medicare can be complicated, but there are a few basics to know
                before jumping into a new plan.
              </Typography>
              <Typography>
                Each Medicare plan has different benefits:
              </Typography>
              <List>
                <ListItem>Part A - hospital insurance</ListItem>
                <ListItem>Part B - medical insurance</ListItem>
                <ListItem>
                  Part C (Medicare Advantage) - varies by plan, but may include
                  vision and dental in addition to Original Medicare benefits
                </ListItem>
                <ListItem>Part D - drug/prescription coverage</ListItem>
                <ListItem>
                  Medigap (Supplement) - added on to Original Medicare and helps
                  pay for expenses
                </ListItem>
              </List>
              <br />
              <br />
              <Typography>
                Consider what you can afford. Some premiums start as low as $0
                per month, but, depending on the plan you choose, it can go up
                rather significantly.
              </Typography>
              <Typography>
                For more complex considerations, call us with lingering
                questions about Medicare enrollment.
              </Typography>
            </Column>
          </Columns>
          <Columns className="icon-two-column">
            <Column className="icon-column">
              <StaticImage
                src="../images/icons/purple-tv.svg"
                alt="tv icon"
                layout="fixed"
                width={100}
                height={100}
                className="column-icon"
              />
            </Column>
            <Column className="content-column">
              <Typography variant="h5" color="primary">
                How to enroll
              </Typography>
              <Typography variant="body">
                If you are ready to enroll in Original Medicare, you can do that
                easily on the Social Security website. The site walks you
                through the process of getting Medicare Parts A and B.
              </Typography>
              <Typography variant="body">
                If you’re looking for additional benefits, getting a Medicare
                Advantage or a supplemental plan might be the way to go. Our
                experts can help you find the right Medicare Advantage plan for
                you.
              </Typography>
            </Column>
          </Columns>
        </VariableContent>

        <SplitContent
          backgroundColor="lightGray"
          alignImageToBottom={false}
          image={
            <img
              src={coupleWalkingBeach}
              alt="an elderly couple walks along the beach"
            />
          }
          mainContent={
            <>
              <Typography variant="h2">Enroll in Medicare today!</Typography>
              <Typography variant="body">
                Ready to enroll in a Medicare plan? If you need help during the
                application process, contact a friendly licensed agent.
              </Typography>
              <Typography variant="body">
                Need more info before you enroll? No problem. You can request a
                FREE Medicare insurance quote from a licensed agent today!
              </Typography>
              <div className="card bg-light">
                <Typography variant="h4">
                  Request your complimentary Medicare insurance quote{' '}
                </Typography>
                <LinkButton
                  variant="feature"
                  to="/form"
                  color="primary"
                  className="margin-x-auto"
                >
                  Get Quote
                </LinkButton>
              </div>
            </>
          }
        />
      </>
    ),
  }

  return <Layout {...layoutProps} />
}

export default Home

export const query = graphql`
  query HomeQuery {
    allBrandyDisclaimer {
      edges {
        node {
          brandy_id
          text
          symbol
        }
      }
    }
  }
`
